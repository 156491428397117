#new-license {
  padding: 1em 6em 1em 6em;

  #title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }

  #customer, #impression-limit, #duration-limit {
    display: flex;
    flex-direction: column;

    .number-input {
      width: 20%;
    }
  
    & input {
      width: 40%;
    }

    & label {
      width: fit-content;
    }
  }

  .input-container {
    padding-top: 15px;

    & label {
      font-size: 16px;
      padding-left: 20px;
      font-weight: 700;
    }

    & input {
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0px 1px 3px #ddd;
      box-sizing: border-box;
      color: #316DCC;
      display: inline-block;
      margin: 8px 0px;
      padding-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 12px;
    }
  }

  #apps-container {
    margin-top: 10px;
    margin-bottom: 20px;

    .apps-title {
      display: block;
      font-size: 16px;
      font-weight: 700;
      padding-left: 20px;
      width: fit-content;
    }
  
    #platform-selector {
      margin-top: 15px;
      padding-left: 20px;

      & button {
        background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
        border-color: #316DCC;
        border-radius: 15px;
        color: white;
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        margin-left: 25px;
        padding: 5px 15px;

        &:hover {
          background: radial-gradient(#5CA2ED, #467ED6);
        }
      }
    }

    #platforms {
      .row {
        align-items: baseline;
        display: flex;
        flex-direction: row;

        .platform {
          border-radius: 14px;
          color: var(--text-color);
          font-size: 14px;
          margin-bottom: 0.75em;
          padding: 2px 5px;
          text-transform: uppercase;
          width: fit-content;
        }

        & button {
          background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
          border-color: #316DCC;
          border-radius: 15px;
          color: white;
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          margin-left: 25px;
          padding: 5px 15px;
          width: fit-content;
  
          &:hover {
            background: radial-gradient(#5CA2ED, #467ED6);
          }
        }
      }
    }

    & select {
      -webkit-appearance: none;
      appearance: none;
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 100%;
      background-position-y: 8px;
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0px 1px 3px #ddd;
      box-sizing: border-box;
      color: #316DCC;
      min-width: 100px;
      padding-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 12px;
      text-transform: capitalize;
    }
  }

  .checkbox-container {
    display: flex;
    gap: 15px;
    grid-template-columns: 26pc auto;
    font-size: 16px;
    line-height: 26px;
    margin-top: 15px;
    padding-left: 20px;

    & input {
      -webkit-appearance: none;
      appearance: none;
      border: 2px solid black;
      border-radius: 2px;
      display: grid;
      height: 26px;
      margin: 0;
      place-content: center;
      transform: translateY(-1px);
      width: 26px;

      &::before {
        box-shadow: inset 1em 1em blue;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        content: "";
        height: 13px;
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        width: 13px;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }

  #create-license {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15px;
  
    .create-license-button {
      background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
      border-color: #316DCC;
      border-radius: 15px;
      color: white;
      cursor: pointer;
      display: block;
      font-size: 20px;
      font-weight: bold;
      margin-left: auto;
      margin-right: auto;
      margin-top: 15px;
      padding: 5px 15px;
  
      &:hover {
        background: radial-gradient(#5CA2ED, #467ED6);
      }
    }

    #error-message {
      color: rgb(210, 0, 0);
      padding-top: 1em;
      margin: auto;
    }
  }
}

.white-text {
  color: white;
}

#select-license {
  display: flex;
  justify-content: space-evenly;
  margin-top: 2em;
}

.license-selection {
  background-color: #caf0f8;
  border: 3px black solid;
  border-radius: 5px;
  box-shadow: 1em 0.5em 10px #223035;;
  font-size: xx-large;
  font-weight: 500;
  height: fit-content;
  text-align: center;
  width: fit-content;

  & img {
    margin: 1em;
  }

  &:hover {
    background-color: #90e0ef;

    & * {
      filter: contrast(0.4);
    }
  }
}

@media screen and (max-width: 1150px) {
  .license-selection {
    height: 20%;
    width: 40%;

    &:hover{
     .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
    }

    .tooltiptext::after {
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: #555 transparent transparent transparent;
    }

    & img {
      width: 30%;
    }
  }
}

/* Tooltip container */
.license-selection {
  position: relative;
}

/* Tooltip text */
.license-selection .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  width: 120px;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */

  /* Fade in tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

/* Tooltip arrow */
.license-selection .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

/* Show the tooltip text when you mouse over the tooltip container */
.license-selection:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

#customer label {
  width: fit-content;
}

#add-license-tooltip {
  width: 30%;
}

#is-active > div, #watermark > div, #production > div, #apps-domains > div, #webgl > div {
  width: fit-content;
}

@media (max-width: 480px) {
  .license-selection {
    font-size: x-large;
  }

  #new-license {
    padding: 0.75em 1em 0.75em 1em;
    
    #title {
      font-size: large;
    }

    #my-tooltip {
      display: none;
    }

    #apps-container {
      #platform-selector {
        padding-left: 0;
      }

      #platforms {
        .row {
          display: flex;
        }
      }

      & button {
        margin-top: 0.25em;
      }

      & select {
        background-position-y: 0.1em;
        font-size: small;
        margin-bottom: 0.5em;
        padding: 0.5em;
      }
    }
  }
}