#logout {
    background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
    border-color: #316DCC;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 15px;
}

#logout:hover {
  background: radial-gradient(#5CA2ED, #467ED6);
}

@media (max-width: 480px) {
  #logout {
      font-size: small;
      margin-right: 0;
  }
}