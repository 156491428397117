#header {
    align-items: center;
    background-color: #85CCF0;
    display: flex;
    justify-content: space-between;
    padding: 5px 5%;
}

#logo {
    cursor: pointer;
    height: 42px;
}

#test-license-button {
    background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
    border-color: #316DCC;
    border-radius: 15px;
    color: white;
    cursor: pointer;
    display: block;
    font-size: 16px;
    font-weight: bold;
    padding: 2px 15px;
    margin: 0.5em 0.75em 0.5em;
}

#test-license-button:hover {
    background: radial-gradient(#5CA2ED, #467ED6);
}

#menu {
    display: flex;
}

#logout-div {
    align-items: flex-end;
    display: flex;
    font-size: small;
}

#version {
    cursor: pointer;
}

#dev {
    background-color: rgb(60, 93, 212);
    border-radius: 2em;
    color: rgb(120, 2, 2);
    font-weight: 700;
    padding: 0.75em;
}

@media (max-width: 480px) {
    #header {
        align-items: flex-start;
    }

    #header * {
        font-size: small;
    }

    #logout-div {
        width: 30%;
    }

    #logout-div > button {
        margin-bottom: 0.25em;
    }

    #test-license-button {
        margin-top: 0;
    }

    #version {
        font-size: xx-small;
    }
}