.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.error-message {
    color: rgb(220, 0, 0);
}

.submit {
    margin: 2em;
}

form {
    width: 30%;
}

input {
    width: 100%;
}