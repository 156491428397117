#local-platforms {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    flex-wrap: wrap;
    gap: 1.5em;
    padding: 1em 0;

    #platform-list {
        background-color: white;
        border: 1px solid #85CCF0;
        border-radius: 10px;
        margin-left: 1em;
        padding: 0 1em;
        position: absolute;
        transition: visibility 200ms;
        visibility: hidden;
        width: 7.5em;
        z-index: 1;
        max-height: 20%;
        overflow-y: auto;

        *:first-child {
            margin-top: 0.5em;
        }

        *:last-child {
            margin-bottom: 0.5em;
        }

        .filtered-platform {
            cursor: pointer;

            &:hover {
                background-color: #85CCF0;
            }
        }
    }

    &:has(input:focus) #platform-list {
        visibility: visible;
    }

    #selected-platforms {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
        padding: 1em;

        .platform {
            align-items: center;
            border-radius: 5px;
            color: var(--text-color);
            display: flex;
            gap: 0.5em;

            .platform-name {
                font-size: smaller;
                font-weight: 600;
                padding: 0.1em 0 0.1em 0.3em;
                text-transform: uppercase;
            }

            .delete-local-platform {
                border-left: 1px solid var(--text-color);
                padding: 0.1em 0.3em;
                padding-left: 0.25em;
                cursor: pointer;

                &:hover {
                    border-radius: 0 5px 5px 0;
                    background-color: rgb(180, 10, 10);
                    color: white;
                }
            }
        }
    }

    #selector-div {
        #local-input {
            padding: 0.5em;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
}