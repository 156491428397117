.app-domain {
    margin: 1em;
    align-items: center;


    .disable-button {
        width: 6.5em;
    }

    .name {
        margin-top: 0.3em;
        width: 20%;
        flex-grow: 2;
        font-weight: 600;
        text-align: center;
    }

    .row {
        display: flex;
        font-size: smaller;
        gap: 0.75em;
        padding-left: 0.75em;
        width: 10em;

        .duration-limit {
            width: 5em;
        }
    }

    .enabled {
        background: rgb(0, 145, 0);
        border-color: rgb(10, 85, 10);
    }

    .enabled:hover {
        background: rgb(2, 100, 2);
        border-color: rgb(8, 50, 8);
    }

    .disabled {
        background: red;
        border-color: rgb(165, 10, 10);
    }

    .disabled:hover {
        background: rgb(189, 10, 10);
        border-color: rgb(150, 15, 15);
    }
    
    td {
        height: 2em;

        &.daily-impressions {
            text-align: center;
        }
    };

    .first-impression, .last-impression {
        min-width: 10em;
    }

}

.update-app {
    padding: 1em;

    #head-container {
        display: flex;
        margin-bottom: 1.5em;

        #back-button {
            height: 3em;
        }
    }

    .impression-limit {
        flex-grow: 1;
        width: fit-content;
    }

    .limit {
        color: rgb(230, 40, 40);
    }
    
    .impressions {
        margin-top: 0.3em;
        margin-right: 1em;
    }

    .last-impression {
        padding-left: 1em;
    }

    #platform-container {
        margin-bottom: 1.5em;

        .platform {
            border-radius: 14px;
            color: var(--text-color);
            font-size: 14px;
            padding: 2px 5px;
            text-transform: uppercase;
            width: fit-content;
            text-align: center;
            font-weight: 700;
        }

        > * {
            font-size: 18px;
            margin-right: 1em;
        }
    }

    #title {
        text-transform: none;
    }

    .tracking {
        text-align: center;
        text-align: -webkit-center;
        justify-content: center;
        margin-top: 0.5em;
        > * {
            margin: 0 10px;
            display: flex;
            align-items: center;
        }
    }
}


#additional-buttons {
    margin: 5% auto;
    width: 50%;
    display: flex;
    flex-wrap: wrap;
}

#additional-buttons > button{
    margin: 5% auto;
} 

#update-app #platform-container 

button {
    margin-right: 2em;
}

.timelock {
    margin-left: 2em;
    width: fit-content;
}

table {
    border-spacing: 0 0.75em;
}

.hidden {
    visibility: hidden;
}

table {
    .platform {
        border-radius: 14px;
        color: var(--text-color);
        font-size: 14px;
        padding: 2px 5px;
        text-align: center;
        text-transform: uppercase;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
    }
}

@media screen and (max-width: 1000px) {
    table#apps-container {
        margin: auto;

        & caption {
            font-size: 1.3em;
        }

        & thead {
            border: none;
            clip: rect(0 0 0 0);
            height: 1px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            width: 1px;
        }

        & tr {
            display: block;
            margin-bottom: .625em;
        }

        & td {
            border-bottom: 1px solid #ddd;
            display: block;
            font-size: .8em;
            text-align: right;
            width: fit-content;

            & button {
                background: radial-gradient(ellipse 250px 50px,#316dcc,#444);
                border-color: #316dcc;
                border-radius: 15px;
                color: #fff;
                cursor: pointer;
                font-size: 14px;
                font-weight: 700;
                margin-left: 25px;
                padding: 5px 15px;

                &.enabled {
                    background: rgb(0, 145, 0);
                    border-color: rgb(10, 85, 10);
                }
            
                &.enabled:hover {
                    background: rgb(2, 100, 2);
                    border-color: rgb(8, 50, 8);
                }
            
                &.disabled {
                    background: red;
                    border-color: rgb(165, 10, 10);
                }
            
                &.disabled:hover {
                    background: rgb(189, 10, 10);
                    border-color: rgb(150, 15, 15);
                }
            }

            &::before {
                /*
                * aria-label has no advantage, it won't be read inside a table
                content: attr(aria-label);
                */
                content: attr(data-label);
                float: left;
                font-weight: bold;
                text-transform: uppercase;
                margin-right: 1em;
            }

            &:last-child {
                border-bottom: 0;
            }
        }
    }
}


#error-message {
    order: 2;
}