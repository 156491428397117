#update-license {
  #apps-container {
    margin-top: 10px;
    margin-bottom: 1.5em;

    .apps-title {
      display: block;
      font-size: 16px;
      font-weight: 700;
      padding-left: 20px;
      width: fit-content;
    }

    .platform-container {
      .domain {
        .row {
          align-items: center;
          display: flex;
        }
      }
    }

    #platform-selector {
      margin-left: 1em;
      margin-top: 15px;
      padding-left: 20px;
    
      & select {
        font-size: 16px;
        -webkit-appearance: none;
        appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 100%;
        background-position-y: 3px;
        border: 1px solid #ccc;
        border-radius: 4px;
        box-shadow: inset 0px 1px 3px #ddd;
        box-sizing: border-box;
        color: #316DCC;
        min-width: 100px;
        padding-bottom: 6px;
        padding-left: 18px;
        padding-right: 18px;
        padding-top: 6px;
        text-transform: capitalize;
      }
    }
  }

  #customer {
    display: flex;
    flex-direction: column;
  
    & input {
      width: 40%;
    }
  }

  #impression-limit, #duration-limit {
    display: flex;
    flex-direction: column;

    #duration-limit-div {
      align-items: center;
      display: flex;
      gap: 0.5em;
    }

    .number-input {
      width: 10em;
    }
  }

  .checkbox-container {
    gap: 15px;
    font-size: 16px;
    line-height: 26px;
    margin-top: 15px;
    padding-left: 20px;

    & input {
      -webkit-appearance: none;
      appearance: none;
      border: 2px solid black;
      border-radius: 2px;
      display: grid;
      height: 26px;
      margin: 0;
      place-content: center;
      transform: translateY(-1px);
      width: 26px;

      &::before {
        box-shadow: inset 1em 1em blue;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        content: "";
        height: 13px;
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        width: 13px;
      }

      &:checked::before {
        transform: scale(1);
      }
    }
  }

  .input-container {
    padding-top: 15px;

    & input {
      border: 1px solid #ccc;
      border-radius: 4px;
      box-shadow: inset 0px 1px 3px #ddd;
      box-sizing: border-box;
      display: inline-block;
      margin: 8px 0px;
      padding-bottom: 12px;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 12px;
    }

    & label {
      font-size: 16px;
      margin-left: 20px;
      font-weight: 700;
      width: fit-content;
    }
  }

  #title {
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    text-transform: none;
    width: fit-content;
  }
}

#download-sheet {
  cursor: pointer;
  margin-left: 2em;
  width: 2em;

  &.disabled {
    cursor: not-allowed;
    svg {
      filter: invert(60%) sepia(0%) saturate(38%) hue-rotate(138deg) brightness(84%) contrast(92%);
    }
  }

  &:hover &:not(.disabled){
    filter: invert(70%) sepia(39%) saturate(6049%) hue-rotate(192deg) brightness(94%) contrast(90%);
  }
}

#update-license #apps-container #platform-selector button,
.reset-impressions {
  background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
  border-color: #316DCC;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 25px;
  padding: 5px 15px;
}

#update-license #apps-container #platform-selector button:hover, .reset-impressions:hover, #update-button:hover {
  background: radial-gradient(#5CA2ED, #467ED6);
}

.no-update {
  pointer-events: none;
}

#impression-container {
  display: flex;
  flex-direction: column;
  margin-left: 1em;
}

#impressions {
  display: flex;
  flex-direction: row;
}

#impressions > * {
  padding-top: 0.5em;
}

#update-license {
  padding: 1em 4em 1em 4em;
}

.delete-button {
  background: red;
  border-color: rgb(165, 10, 10);
}

.delete-button:hover {
  background: rgb(189, 10, 10);
  border-color: rgb(150, 15, 15);
}

#platforms {
  margin: 1em;

  .add-production {
    display: flex;
    gap: 1em;
    
    .add-app-button {
      width: auto;
    }
    
    .set-production-app {
      display: flex;
      width: auto;

      & input {
        margin: 0;
        padding: 0.5em 0.5em;
        width: auto;
      }
    }
  }

  .platform {
    padding-left: 1em;
    padding-right: 1em;
  }
}

#platforms > * {
  margin: 0.5em 0 0.5em 0;
}

#update-button {
  width: 10em;
  margin: auto;
  display: flex;
  justify-content: center;
}

#timelock {
  align-items: center;
  display: flex;
  gap: 2em;
  width: fit-content;
}


#timelock-input, .timelock {
  color: #316DCC;
  margin-left: 2em;
  width: fit-content;
}

#apps-container {
  margin-bottom: 1.5em;
}

#last-impression {
  display: flex;
  flex-direction: column;

  #last-impression-title {
      font-size: large;
      font-weight: 600;
      padding: 1em 0;
  }
}

#local-impressions {
  padding: 1em 0;
}

@media (max-width: 480px) {
  #update-license {
    padding: 0.75em 1em 0.5em 1em;

    #apps-container {
      .platform-container {
        .domain {
          .row {
            align-items: flex-start;
          }
        }
      }

      #platform-selector {
        & button {
          margin-top: 0.25em;
        }

        & select {
          background-position-y: 0.1em;
          font-size: small;
          margin-bottom: 0.5em;
          padding: 0.5em;
        }
      }
    }

    #my-tooltip {
      display: none;
    }

    #timelock {
      align-items: self-start;
      gap: 0;
      flex-direction: column;
    }

    #title {
      font-size: large;
    }

    #platforms {
      border-spacing: 0;

      & tbody {
        & tr {
          align-items: center;
          display: flex;
          flex-wrap: wrap;

          .add-production {
            flex-wrap: wrap;
          }
          
          & button {
            margin: 0.15em 0.5em;
          }
        }
      }
    }
  }
}

#error-message {
  color: rgb(210, 0, 0);
  padding-top: 1em;
  margin: auto;
  text-align: center;
}

#create-license {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
}

.center {
  width: fit-content;
  margin: auto;
}

.flex { 
  display: flex;
}