.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body {
  background-color: rgb(231, 242, 247);
}

button {
  background: radial-gradient(ellipse 250px 50px,#316dcc,#444);
  border-color: #316dcc;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-left: 25px;
  padding: 5px 15px;
}

button:hover {
  background: radial-gradient(#5CA2ED, #467ED6);
}

a {
  color: black;
  text-decoration: none;
}

.hisense {
  --text-color: white;
  background-color: rgb(0, 154, 157);
}

.webos {
  --text-color: white;
  background-color: rgb(216, 5, 70);
}

.tizen {
  --text-color: white;
  background-color: rgb(36, 139, 209);
}

.html5 {
  --text-color: black;
  background-color: rgb(249, 208, 196);
}

.chromecast {
  --text-color: white;
  background-color: rgb(37, 38, 39);
}

.ps4,
.ps5 {
  --text-color: white;
  background-color: rgb(17, 92, 105);
}

.xbox {
  --text-color: white;
  background-color: rgb(9, 104, 9);
}

.android {
  --text-color: white;
  background-color: rgb(24, 185, 24);
}

.ios,
.macos,
.visionos {
  --text-color: rgb(21, 21, 21);
  background-color: white;
  border: 1px solid black;
}

.windows {
  --text-color: white;
  background-color: rgb(30, 90, 120);
}

input[type="checkbox"] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: rgb(231, 242, 247);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: currentColor;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid rgb(25, 125, 215);
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

input[type="checkbox"]::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em green;
  background-color: CanvasText;
}

input[type="checkbox"]:checked::before {
  transform: scale(1);
}

#production {
  display: flex;
  justify-content: flex-start;
}

#production > * {
  margin-right: 1em
}

#watermark {
  display: flex;
  justify-content: flex-start;
}

#watermark > * {
  margin-right: 1em
}

#is-active {
  display: flex;
  justify-content: flex-start;
}

#is-active > * {
  margin-right: 1em
}

#tracking {
  display: flex;
  justify-content: flex-start;
}

#tracking > * {
  margin-right: 1em
}

#webgl {
  display: flex;
  justify-content: flex-start;
}

#webgl > * {
  margin-right: 1em
}

.license-disabled {
  color: rgb(230, 40, 40);
  font-weight: 800;
}

#title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

@media (max-width: 480px) {
  #title {
    font-size: large;
    margin-bottom: 0.25em;
  }
}

.platform > label {
  color: var(--text-color);
}