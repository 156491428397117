.asterisk-error {
  padding-left: 0.5em;
}

.platform-container {
  margin-top: 10px;
  width: 25%;
}

.platform-container .platform {
  margin-left: 20px;
  white-space: nowrap;
}

.platform-container .platform label {
  border-radius: 14px;
  font-size: 14px;
  padding: 2px 5px;
  text-transform: uppercase;
}

.platform-container .platform button {
  background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
  border-color: #316DCC;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin-left: 15px;
  padding: 2px 5px;
}

.platform-container .platform button:hover {
  background: radial-gradient(#5CA2ED, #467ED6);
}

.platform-container .domain {
  margin-bottom: 1em;
  position: relative;
}

.platform-container .domain input {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0px 1px 3px #ddd;
  box-sizing: border-box;
  display: inline-block;
  font-size: 14px;
  margin: 4px 0px;
  min-width: 250px;
  padding-bottom: 6px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 6px;
  flex-shrink: 0;
}

.platform-container .domain button {
  background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
  border-color: #316DCC;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin: 0 .25em 0 .25em;
  padding: 2px 5px;
  right: 3px;
  top: 7px;
}

.platform-container .domain button:hover {
  background: radial-gradient(#5CA2ED, #467ED6);
}

.platform-container .domain .timelock-button {
  right: 75px;
}

.platform-container .domain .watermark-button {
  right: 160px;
}

.platform-container .domain .disabled {
  background: radial-gradient(ellipse 250px 50px, #960c2e, #740202);
  border-color: #630b11;
}

.platform-container .domain .disabled:hover {
  background: radial-gradient(#6c041e, #4c0000);
}

.platform-container .domain .enabled {
  background: radial-gradient(ellipse 250px 50px, #08a37f, #0a5e4e);
  border-color: #0d8686;
}

.platform-container .domain .enabled:hover {
  background: radial-gradient(#027b5f, #053d33);
}

.timelock {
  width: fit-content;
}

.timelock-expired {
  color: red;
  padding-left: 1em;
  white-space: nowrap;
}

.platform-buttons {
  display: flex;
}

.platform .impression-limit-div {
  padding: 0.5em 0;
  width: 8em;
}

.platform .impression-limit-div .impression-limit {
  margin: 0 1em;
}

@media (max-width: 480px) {
  .row {
    flex-direction: column;
  }

  .platform-container {
    width: 100%;
  }

  .platform-container .platform {
    padding: 0.5em 0 1em 0;
  }

  .platform-container .domain {
    margin-bottom: 0;
  }
}