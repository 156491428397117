#last-impression-container {
    display: flex;
    flex-direction: row;
    gap: 2em;

    & tbody {
        & tr {
            & td {
                padding: 0 0.5em;

                &:first-child {
                    font-weight: 600;
                }
            }
        }
    }

    .labels {
        font-weight: 900;
    }

    &:last-child {
        padding-bottom: 1em;
    }
}