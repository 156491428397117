#customer-input {
    min-width: 400px;
}

@media (max-width: 480px) {
    #customer {
        #customer-input {
            min-width: 0;
            width: 100%;
        }
    }
}