#dashboard {
    padding: 25px;
}

#dashboard #title {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  text-transform: uppercase;
  width: 100%;
}

#dashboard #search-bar {
    display: flex;
    width: 50%;
    margin: auto;
    padding-bottom: 1em;
}

#dashboard #search-bar input {
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0px 1px 3px #ddd;
  box-sizing: border-box;
  display: inline-block;
  margin: 8px 0px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 12px;
  width: 100%;
}

#dashboard .buttons {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#dashboard .buttons .refresh {
    display: flex;
}

#dashboard .buttons .button {
    background: radial-gradient(ellipse 250px 50px,#316dcc,#444);
    border-color: #316dcc;
    border-radius: 15px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    font-weight: 700;
    padding: 5px 15px;
}

#dashboard .buttons .button:hover {
    background: radial-gradient(#5CA2ED, #467ED6);
}

#dashboard .buttons .refresh-time {
    font-size: 16px;
    line-height: 30px;
    padding-left: 10px;
}

#dashboard .license-container {
    border: 2px solid #85CCF0;
    border-radius: 15px;
    background-color: white;
    display: flex;
    padding: 10px;
    margin: 10px 0px;
}

#dashboard .license-container:hover {
    background-color: #85CCF0;
}

#dashboard .license-container .license {
    width: 100%;
}

#dashboard .license-container .delete {
    cursor: pointer;
    margin: auto auto auto 15px;
    height: 40px;
    width: 40px;
    filter: invert(9%) sepia(90%) saturate(5026%) hue-rotate(358deg) brightness(106%) contrast(97%);
}

#dashboard .license-container .delete:hover {
    filter: none;
}

#dashboard .license-container .license .license-info {
    display: flex;
    justify-content: space-between;
}

#dashboard .license-container .license .license-info .expired,
#dashboard .license-container .license .license-info .inactive {
    background: red;
    border-radius: 10px;
    color: white;
    padding: 0px 5px;
}

#dashboard .license-container .license .platforms {
    margin-top: 5px;
    position: relative;
}

#dashboard .license-container .license .platforms .platform {
    border-radius: 10px;
    display: inline-block;
    padding: 0px 5px;
    position: relative;
}

#dashboard .license-container .license .platforms .platform .platform-name {
    color: var(--text-color);
    text-transform: uppercase;
}

#dashboard .license-container .license .platforms .platform + .platform {
    margin-left: 10px;
}

* > .tooltipdomain {
    visibility: hidden;
    width: fit-content;
    background-color: #555;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text */
    position: absolute;
    z-index: 1;
    bottom: 90%;
    right: -50%;

    /* Fade in tooltip */
    opacity: 0;
    transition: opacity 0.3s;
}

* > .tooltipdomain::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

*:hover > .tooltipdomain {
    visibility: visible;
    opacity: 1;
}

#dashboard .load-next {
  background: radial-gradient(ellipse 250px 50px, #316DCC, #444444);
  border-color: #316DCC;
  border-radius: 15px;
  color: white;
  cursor: pointer;
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  padding: 5px 15px;
}

#dashboard .load-next:hover {
  background: radial-gradient(#5CA2ED, #467ED6);
}

.support-link {
    margin-left: .25em;
    color: rgb(77, 113, 125);
}

.support-link:hover {
    color: rgb(38, 67, 77);
}

#copied-message {
    background-color: gray;
    border-radius: 15px;
    bottom: 5%;
    color: white;
    left: 50%;
    padding: 0.25em;
    position: fixed; 
    transform: translate(-50%, -50%);
    width: fit-content;
}

#expired-checkbox {
    margin-right: 0.5em;
    position: relative;
    bottom: -0.1em;
}

#expired-checkbox::before {
    box-shadow: inset 1em 1em #5CA2ED;
}

.impressions {
    margin-top: 0.3em;
    padding-left: 1em;
}

.additional-info {
    display: flex;
}

#filters {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin: auto;
    padding-bottom: 1em;
    width: 50%;
}

#filters-selectors {
    display: flex;

    .selector-label {
        padding-right: 0.5em;
    }

    #expirity-selector, #type-selector, #platform-selector {
        padding-left: 1.5em;

        & select, #platform-options {
            margin-top: 0.5em;
        }
    }

    #platform-selector {
        #platform-options {
            display: flex;
            flex-wrap: wrap;
            gap: 1em;

            .platform-option {
                display: flex;
                gap: 0.25em;
            }

            & input[type="checkbox"] {
                transform: translateY(0.05em);
            }
        }
    }
}

#arrow {
    height: 1em;
}

#arrow.open {
    transform: rotate(90deg);
}

#filter-text {
    padding-left: 0.5em;
}

#filter-button {
    padding-bottom: 1em;
    width: fit-content;
}


.watermark {
    height: 1em;
    padding-left: 0.75em;
}

@media (max-width: 480px) {
    #dashboard {
        .buttons {
            .button {
                font-size: small;
                padding: 0.25em 1em;
                text-align: center;
                margin-right: 0;
            }

            .refresh {
                align-items: center;
            }

            .refresh-time {
                font-size: small;
                line-height: 15px;
            }
        }

        #filters {
            padding-bottom: 0.25em;
            width: 90%;

            #arrow {
                height: 14px;
            }

            #filter-button {
                padding-bottom: 0.25em;
            }

            #filters-selectors {
                flex-wrap: wrap;

                #platform-selector {
                    margin-top: 0.5em;

                    #platform-options {
                        gap: 0.75em;
                        
                        .platform-option {
                            font-size: smaller;
                            gap: 0.3em;
                        }
                    }
                }
            }

            & label {
                font-size: 14px;
            }
        }

        #licenses {
            .license-container {
                font-size: small;

                .license {
                    .license-info {
                        display: block;

                        .expired, .inactive {
                            width: fit-content;
                        }

                        & div {
                            margin-bottom: 0.4em;
                            width: fit-content;
                        }
                    }
                }

                .delete {
                    height: 30px;
                }
            }
        }

        #search-bar {
            padding-bottom: 0.5em;
            width: 90%;
        }
        
        #title {
            font-size: large;
            margin-bottom: 0.25em;
        }
    }
}