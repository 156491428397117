#panel {
    #title {
        width: 30%;
        margin: auto;
    }

    #test-form {
        width: 25%;
        display: flex;
        flex-direction: column;
        margin: auto;

        .field {
            display: flex;
            justify-content: space-between;
        }

        input {
            width: 50%;
        }

        select {
            width: 50%;
        }
    }

    #dev-mode {
        margin: auto;
        width: fit-content;
        color: red;
    }

    #response {
        margin: 2em auto;
        width: fit-content;
        padding: 0.5em;

        #additional-parameters {
            padding: 1em 0 0 0;
            display: flex;
            flex-direction: column;
        }

        #error-code {
            padding: 1em 0 0 0;
        }

        &.active {
            border: 1px solid green;
        }
    
        &.inactive {
            border: 3px solid red;
        }
    }

    @media (max-width: 480px) {
        #test-form {
            width: 75%;

            & button {
                margin: 1em auto;
                width: 25%;
            }
        }
    }
}
